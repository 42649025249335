#header {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100px;
        > a {
            img {
                width: 194px;
                height: 48px;
            }
        }

        > button {
            width: 40px;
            height: 40px;
            background: var(--ion-color-primary-shade);
            border: 0;
            display: none;
            align-items: center;
            justify-content: center;
            position: relative;
            z-index: 999999;
            @media (max-width: 991px) {
                display: flex;
            }
        }

        > .menu {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 30px;
            @media (max-width: 991px) {
                position: fixed;
                z-index: 999998;
                width: 300px;
                height: 100vh;
                right: -500px;
                top: 0;
                background-color: var(--ion-color-primary);
                flex-direction: column;
                align-items: center;
                justify-content: center;
                transition: .3s;

                &.active {
                    right: 0;
                }
            }

            a {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 100%;
                letter-spacing: -0.025em;
                color: var(--ion-text-color);
                @media(max-width: 991px) {
                    color: white;
                }

                &:last-child {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 100%;
                    letter-spacing: -0.025em;
                    color: white;
                    padding: 12px 16px;
                    background-color: var(--ion-color-primary);
                    @media(max-width: 991px) {
                        display: none;
                    }
                }
            }
        }
    }
}
