#footer {
    background-color: var(--ion-color-primary);
    color: white;
    padding-top: 75px;
    padding-bottom: 30px;
    margin-top: 0;
    .container {
        > div {
            display: grid;
            grid-template-columns: 2fr 1fr 1fr 1fr 1fr;

            @media (max-width: 991px) {
                grid-template-columns: 1fr;
                gap: 50px;
            }

            > div {
                p {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 150%;
                    text-align: right;
                    letter-spacing: -0.025em;
                    color: white;
                    margin-bottom: 30px;
                    @media (max-width: 991px) {
                        text-align: center;
                    }
                }

                > div {
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                    a {
                        color: white;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 150%;
                        text-align: right;
                        letter-spacing: -0.025em;
                        @media (max-width: 991px) {
                            text-align: center;
                        }
                    }
                }

                > img {
                    width: 128px;
                    height: 32px;
                    margin-bottom: 60px;
                    @media (max-width: 991px) {
                        margin: 0 auto 60px;
                    }
                }

                .socials {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 8px;
                    @media(max-width: 991px) {
                        justify-content: center;
                    }
                }
            }
        }

        .creditos {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-top: 32px;
            margin-top: 32px;
            border-top: 1px solid #fff;

            @media (max-width: 991px) {
                flex-direction: column;
                text-align: center;
            }

            p {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 150%;
                letter-spacing: -0.025em;
                color: white;

                a {
                    color: white;
                    font-weight: bold;
                }
            }
        }
    }
}
